// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #0A61B2;
$h2-color: #82B102;
$h3-bg-color: #0A61B2;
$h4-bg-color: #82B102;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Montserrat', Arial, sans-serif;
}

.body_50577 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_50577 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 84%;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;

	@media only screen and (max-width: 1260px) {
		width: 95%;
	}
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_50577 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 90px;
	left: 0;

	@media only screen and (max-width: 990px) {
		right: 0;
		top: 20px;
	}

	a {
		display: flex;
		align-items: center;

		@media only screen and (max-width: 990px) {
			flex-direction: column;
		}

		img {

			&:nth-of-type(1) {}


			&:nth-of-type(2) {
				margin: 40px 0px 0 20px;
			}
		}

	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 1010px;
	top: 60px;
	z-index: 99;

	@media only screen and (max-width: 1500px) {
		right: 810px;
	}

	@media only screen and (max-width: 1130px) {
		right: 0;
		top: 120px;
	}

	@media only screen and (max-width: 990px) {
		top: 50px;
	}

	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 10px;

		@media only screen and (max-width: 990px) {
			display: block;
			margin: 10px 0;
		}

		a {
			display: block;
			@include box(100%);
		}

		//	#facebook-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/fb-ic.png) no-repeat center; } 
		//	#twitter-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: #0A61B2;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 80px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_50577 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// HOME-BTNS SEC
// -----------------------------------//

#home-btns {
	width: 100%;
	position: absolute;
	top: -190px;

	@media only screen and (max-width: 990px) {
		position: relative;
		top: auto;
		padding: 40px 0;
	}

	ul {
		padding: 0 15px;
		display: flex;
		gap: 15px;

		@media only screen and (max-width: 990px) {
			flex-wrap: wrap;
			justify-content: center;
		}

		li {
			position: relative;
			background: none;
			padding: 0;
			margin: 0;
			width: calc(100% / 4);
			height: 170px;
			border-radius: 20px;
			overflow: hidden;
			transition: 0.4s;

			&:hover {
				filter: brightness(106%);
				translate: 0 -5px;

				&:before {
					scale: 1.2;

				}

				a {

					span {
						translate: 0 -5px;
					}
				}
			}

			@media only screen and (max-width: 990px) {
				width: calc(100%/2 - 20px);
			}

			@media only screen and (max-width: 630px) {
				width: 100%;
			}

			&:before {
				position: absolute;
				content: "";
				transition: 0.4s;

			}

			&:nth-of-type(odd) {
				&:before {
					background: url(/i/design/blue-btn-overlay.png) repeat-x;
					height: 100%;
					width: 100%;
					left: 0;
					bottom: -1px;
				}
			}

			&:nth-of-type(even) {
				&:before {
					background: url(/i/design/green-btn-overlay.png) repeat-x;
					height: 100%;
					width: 100%;
					left: 0;
					bottom: -1px;
				}
			}

			&:nth-of-type(1) {
				background: url(/i/graphics/hp-photo-btns/1.jpg) top right no-repeat;
				background-size: cover;
			}

			&:nth-of-type(2) {
				background: url(/i/graphics/hp-photo-btns/2.jpg) top right no-repeat;

			}

			&:nth-of-type(3) {
				background: url(/i/graphics/hp-photo-btns/3.jpg) top left no-repeat;

			}

			&:nth-of-type(4) {
				background: url(/i/graphics/hp-photo-btns/4.jpg) top right no-repeat;

			}

			a {
				position: relative;
				display: block;
				height: 100%;
				font-size: 30px;
				color: #fff;
				transition: 0.4s;

				@media only screen and (max-width: 1730px) {
					font-size: 1.7vw;
				}

				@media only screen and (max-width: 1100px) {
					font-size: 1.5vw;
				}

				@media only screen and (max-width: 990px) {
					font-size: 20px;
				}

				@media only screen and (max-width: 630px) {
					font-size: 25px;
				}

				span {
					position: absolute;
					bottom: 20px;
					width: 100%;
					text-align: center;
					transition: 0.4s;
				}
			}
		}
	}
}

// -----------------------------------//
// OUR SCHOOLS
// -----------------------------------//


#our-schools {
	position: relative;
	padding: 50px 0;
	background: #fff url(/i/design/main-watermark.png) left center no-repeat;

	@media only screen and (max-width: 990px) {
		background: #fff;
	}

	.wrapper {

		h2 {
			text-align: center;
			color: #000;
			font-size: 48px;
			margin: 10px 0;

			@media only screen and (max-width: 990px) {
				font-size: 40px;
			}

			@media only screen and (max-width: 600px) {
				font-size: 35px;
			}
		}

		p {
			text-align: center;
			font-size: 30px;

			@media only screen and (max-width: 990px) {
				font-size: 25px;
			}

			@media only screen and (max-width: 600px) {
				font-size: 20px;
			}
		}

		ul {
			margin: 60px 0 0 0;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 12px;

			li {
				position: relative;
				background: none;
				padding: 0;
				margin: 0;
				transition: 0.4s;

				&:hover {
					translate: 0 -5px;

					a {

						.school-logo {

							border: 3px solid #d1d3d4;
						}

						p {
							letter-spacing: 1px;
						}
					}
				}

				&:nth-of-type(1) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/holy-trinity.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(2) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/little-engineers.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(3) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/meridian-angel-primary.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(4) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/millbrook-park.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(5) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/st-andrew-st-francis-cofe-primary.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(6) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/st-anns-ce.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(7) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/st-michaels-ce.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(8) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/st-paul-all-hallows.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(9) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/st-richards-cofe.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				&:nth-of-type(10) {
					a {
						.school-logo {
							background: #fff url(/i/design/school-logos/stanwell-fields.jpg) no-repeat center center;

							@media only screen and (max-width: 370px) {
								background-size: 90%;
							}
						}
					}
				}

				a {
					position: relative;
					transition: 0.4s;

					.school-logo {
						height: 202px;
						width: 309px;
						border-radius: 20px;
						border: 1px solid #d1d3d4;
						transition: 0.4s;

						@media only screen and (max-width: 370px) {
							width: 270px;
						}
					}

					p {
						text-align: center;
						color: #000;
						font-size: 20px;
						line-height: 1.2;
						font-weight: 700;
						margin: 20px 0 40px 0;
						transition: 0.4s;

						span {
							display: block;
							font-weight: normal;
							transition: 0.4s;
						}
					}
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	box-shadow: 0px 10px 7px -3px rgba(0, 0, 0, 0.5);
	border-radius: 25px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	box-shadow: 0px 10px 7px -3px rgba(0, 0, 0, 0.5);
	border-radius: 25px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;
	box-shadow: 0px 10px 7px -3px rgba(0, 0, 0, 0.5);
	border-radius: 25px;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background-color: #0A61B2;
	border-radius: 25px;
	box-shadow: 0px 10px 7px -3px rgba(0, 0, 0, 0.4);

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #0A61B2 url(/i/design/footer-bg.jpg) repeat-x;
	padding: 40px 0;
	position: relative;
	z-index: 10;

	@media only screen and (max-width: 990px) {
		background: #0A61B2;
	}

	.wrapper {
		text-align: left;
		font-size: 12px;
		color: #fff;

		@media only screen and (max-width: 1460px) {
			width: 95%;
		}

		@media only screen and (max-width: 990px) {
			text-align: center;
		}

		p {
			margin: 0;
		}

		a[href^="mailto:"],
		a {
			margin: 0;
			color: #00C7D5;

			&:hover {
				text-decoration: underline;
			}
		}

		.border-wrap {
			display: flex;
			align-items: flex-end;
			gap: 40px;

			@media only screen and (max-width: 1560px) {
				gap: 20px;
			}

			@media only screen and (max-width: 1300px) {
				gap: 8px;
				justify-content: space-between;
			}

			@media only screen and (max-width: 990px) {
				flex-direction: column;
				align-items: center;
			}

			.footer-logo {
				margin: 0 60px 0 0;

				@media only screen and (max-width: 1300px) {
					margin: 0;
				}

				@media only screen and (max-width: 1190px) {
					display: none;
				}

				@media only screen and (max-width: 990px) {
					display: block;
					order: 1;
				}
			}

			.address {
				@media only screen and (max-width: 990px) {
					order: 2;
				}
			}

			.map {
				@media only screen and (max-width: 990px) {
					order: 4;
				}
			}

			.info {
				@media only screen and (max-width: 990px) {
					order: 3;
				}
			}

			.sub {
				margin-left: auto;
				text-align: right;

				@media only screen and (max-width: 1300px) {
					margin-left: 0;
				}

				@media only screen and (max-width: 990px) {
					text-align: center;
					order: 5;
				}

				#credit {
					a {
						&:nth-of-type(1) {
							color: #fff;
						}
					}
				}
			}
		}
	}



}



// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

// .sub-footer {
// 	height: 30px;
// 	line-height: 30px;
// 	background: #999;
// 	overflow: hidden;
// 	@extend .clearfix;

// 	p {
// 		font-size: 13px;
// 		color: #fff;
// 		float: left;
// 		margin: 0;
// 	}

// 	a {
// 		color: #fff;
// 	}

// 	#copyright,
// 	#credit {
// 		a {
// 			color: #fff;
// 		}
// 	}

// 	#copyright {
// 		float: left;
// 	}

// 	#credit {
// 		float: right;
// 	}
// }

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;